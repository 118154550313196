<template>
  <div class="bots">
    <div>
      <vs-row>
        <!-- Main component -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-xs="12"
          vs-sm="12"
          vs-lg="12"
        >
          <vs-row>
            <vs-col
              v-if="!botsFiltered || botsFiltered.length === 0"
              class="mb-base"
            >
              <vs-alert title="Oops!" active="true" color="warning">{{
                lang.newChat.wizard.noBots[languageSelected]
              }}</vs-alert>
            </vs-col>

            <!-- Bots -->
            <vs-col
              :key="index"
              v-for="(bot, index) in botsFiltered"
              vs-type="flex"
              vs-justify="center"
              vs-align="stretch"
              vs-xs="6"
              vs-sm="6"
              vs-lg="6"
              class="mb-8 all-bots-view mt-6"
            >
              <BotItem
                :bot="bot"
                :show-actions="false"
                :select-bot="selectBot"
                :bot-selected="botSelected"
                :is-new-chat="true"
              />
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
// import ActionButton from '@/components/kona/ActionButton'

export default {
  props: {
    platform: String,
    setBot: Function,
    date: Number
  },
  components: {
    // ActionButton,
    BotItem: () => import('../../../bot-maker/bots-view/bots/BotItem.vue')
  },
  data() {
    return {
      tryBot: false,
      botSelected: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    ...mapGetters('bots', ['getBots', 'filters']),
    ...mapGetters('integrations', ['integrations']),
    botsFiltered() {
      const services = this.integrations.map(element => element.service._id)
      let tmpArray = this.getBots

      tmpArray = tmpArray.filter(
        element =>
          element._botService &&
          element._botService.id &&
          services.indexOf(element._botService.id) > -1
      )

      return tmpArray
    },
    canAccess() {
      return (
        this.session &&
        this.session.user &&
        this.session.user.roles.canAccess.botMaker
      )
    }
  },
  watch: {
    date(newVal) {
      if (newVal) {
        this.loadBots()
      }
    }
  },
  methods: {
    ...mapActions('bots', ['GET_BOTS']),
    ...mapActions('integrations', ['GET_INTEGRATIONS']),
    navigateToAddBot() {
      this.$router.push('/bot-maker/add')
    },
    selectBot(bot) {
      this.botSelected = this.botSelected === bot.id ? '' : bot.id
      this.setBot(this.botSelected)
    },
    loadBots() {
      this.$vs.loading({
        container: '#button-with-loading',
        scale: 1
      })
      this.GET_INTEGRATIONS({ types: [this.platform] }).finally(() => {
        this.GET_BOTS().finally(() => {
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bots {
  .center {
    text-align: center;
  }

  .add-bot-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0;
    span {
      margin-right: 15px;
      font-weight: bold;
    }
  }
}
.con-vs-alert {
  height: 100% !important;
}
</style>
